<template>
  <div>
    <main class="main-div" :style="{ backgroundImage: 'url('+require('@/assets/bg.jpg')+')' }">
      <el-row justify="center">
        <el-col :sm="20" :md="22" :lg="22">
          <TVHeader/>
          <div class="main-content" v-if="upcoming_appointments.length">
            <el-carousel :interval="15000000" arrow="always" :autoplay="true">
              <el-carousel-item class="height-100vh" v-for="(doctor, index) in upcoming_appointments" :key="index">
                <el-row>
                  <el-col :span="7">
                    <br>
                    <div class="doctor-info">
                      <div>
                        <div v-if="doctor.profile_image">
                          <img :src="userPhoto(doctor.profile_image)" alt="" class="doctor_image">
                        </div>
                        <el-avatar v-else :size="60" src="https://empty">
                          <img
                              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                          />
                        </el-avatar>

                        <h3 class="mb-0"><b>{{ doctor.prefix }} </b> {{ doctor.fullname }}</h3>
                        <p class="mb-0 mt-0">{{ doctor.headline }}</p>
                        <p class="mb-0 mt-0">{{ doctor.speciality }}</p>
                        <p class="mb-0 mt-0">{{ doctor.degree }}</p>
                      </div>

                      <h1 style="color:orange;">Room No # {{ doctor.room_no }}</h1>
                    </div>
                  </el-col>
                  <el-col :span="17">
                    <WaitingPatient :patients="doctor.waiting_patients" v-if="doctor.waiting_patients.length"/>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div v-else>
            <el-empty description="NO APPOINTMENT AVAILABLE"/>
          </div>
        </el-col>
      </el-row>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TVHeader from '@/views/tvScreen/TVHeader';
import WaitingPatient from '@/views/tvScreen/WaitingPatient';

export default {
  name: 'TvScreen',
  components: {
    TVHeader,
    WaitingPatient,
  },
  data() {
    return {
      upcoming_appointments: [],
    };
  },
  created() {
    this.upComingPatient();
  },
  mounted() {
    setInterval(() => {
      this.upComingPatient();
    }, 200000);
  },
  methods: {
    upComingPatient() {
      const floor = this.$route.query.floor ? this.$route.query.floor : null;
      axios.get(`/api/v2/institute/${this.$route.params.id}/waiting-patients${floor ? `?floor=${floor}` : ''}`)
        .then((res) => {
          this.upcoming_appointments = res.data.data;
        });
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  },
};
</script>

<style scoped>

.doctor-info {
  background-color: #3f85ce;
  color: #ffffff;
  padding: 40px 40px;
  height: 91vh;
  text-align: center;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}

.doctor-info p {
  line-height: 25px;
}

.main-div {
  height: 100vh;
  background-position: center;
  background-size: contain;
}

/*.el-carousel__item h3 {*/
/*  color: #475669;*/
/*  opacity: 0.75;*/
/*  line-height: 300px;*/
/*  margin: 0;*/
/*  text-align: center;*/
/*}*/

.el-carousel__item:nth-child(2n) {
  background-color: #ffffff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #ffffff;
}

.height-100vh {
  height: 100vh;
}

.doctor_image {
  height: 200px;
}
</style>
<style>
.el-carousel--horizontal {
  overflow: auto !important;
  height: 90vh;
}
</style>
