<template>
  <header class="header">
    <el-row justify="center">
      <el-col :span="15">
        <div class="d-flex align-items-center">
          <div class="text-center">
            <img :src="require('@/assets/logo.png')" alt="Orko" class="logo" id="sidebar-logo"/>
          </div>
          <div class="mx-30">
            <h2 class="mb-0">ORKO CUSTOMER CARE</h2>
            <p class="my-5"><span><font-awesome-icon icon="fa-solid fa-location-dot" class="mr-5"/></span>
              SFA Tower, 132,Level-5 Panchlaish, Chattogram
            </p>
            <p class="mt-0">
              <font-awesome-icon icon="fa-solid fa-phone" class="mr-5"/>
              09678707808 <span><font-awesome-icon icon="fa-solid fa-envelope" class="mx-5"/></span>ask@myorko.com
            </p>
          </div>
        </div>
      </el-col>
      <el-col :span="9">
        <DigitalClock/>
      </el-col>
    </el-row>
  </header>
</template>

<script>
import DigitalClock from '@/components/core/DigitalClock';

export default {
  name: 'TVHeader',
  components: {
    DigitalClock,
  },
};
</script>

<style scoped>
.header {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: rgb(0 0 0 / 15%) -0.05px 1.95px 0.6px;
}

header {
  background: #ffffff;
}

.logo {
  height: 70px;
}
</style>
