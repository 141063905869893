<template>
    <div id="clock">
        <p class="date">{{ date }}</p>
        <p class="time">{{ time }}</p>
    </div>
</template>
<script>
export default {
  data() {
    return {
      time: '',
      date: '',
    };
  },
  mounted() {
    const timerID = setInterval(this.updateTime, 1000);
  },
  methods: {
    updateTime() {
      const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
      const cd = new Date();
      const hours = cd.getHours() % 12 || 12;
      const amOrPm = cd.getHours() > 12 ? 'PM' : 'AM';
      this.time = `${this.zeroPadding(hours, 2)}:${this.zeroPadding(cd.getMinutes(), 2)}:${this.zeroPadding(cd.getSeconds(), 2)}${amOrPm}`;
      this.date = `${this.zeroPadding(cd.getFullYear(), 4)}-${this.zeroPadding(cd.getMonth() + 1, 2)}-${this.zeroPadding(cd.getDate(), 2)} ${week[cd.getDay()]}`;
    },
    zeroPadding(num, digit) {
      let zero = '';
      for (let i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
  },
};
</script>

<style lang="scss" scoped>
#clock {
    font-family: 'Share Tech Mono', monospace;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    color: olive;
    text-shadow: 0 0 20px rgba(10, 175, 230, 1),  0 0 20px rgba(10, 175, 230, 0);
    .time {
        letter-spacing: 0;
        font-size: 60px;
        padding: 0px 0;
        margin: 0;
    }
    .date {
        letter-spacing: 0.1em;
        font-size: 24px;
        margin: 0;
    }
    .text {
        letter-spacing: 0.1em;
        font-size: 12px;
        padding: 20px 0 0;
    }
}
</style>
