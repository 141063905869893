<template>
  <div class="single_slide">
    <br>
    <div class="w-100">
      <el-card class="w-100 my-5" v-for="(patient,index) in patients" :key="index">
        <div class="d-flex justify-content-between align-items-center">
          <div class="sl-no">
            <h4 style="margin: 0;">SL. #{{index+1}}</h4>
          </div>
          <div style="width: 45%">
            <span class="light-title">Patient Name</span>
            <h4 style="margin: 0;">{{ patient.patient_name }}</h4>
          </div>
          <div style="width: 35%">
            <span class="light-title">Schedule Start</span>
            <h4 style="margin: 0;">{{ patient.schedule_started_at }}</h4>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitingPatient',
  props: {
    patients: {},
  },
};
</script>

<style scoped>

.single_slide {
  /*display: flex;*/
  align-items: center;
  /*justify-content: center;*/
  height: 100%;
  margin: 0px 100px;
}
.sl-no {
  background: #3f85ce;
  color: #ffffff;
  padding: 6px;
}
.light-title {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

</style>

<style lang="scss">
.single_slide {
  .el-card__body {
    padding: 5px 15px;
  }
}
</style>
